export default [
  {
    question: 'What is Oral Fluid screening primarily used for?',
    correct: 2,
    answers: [
      'Detection of very recent use of drugs and alcohol',
      'Detection of historical drug use',
      'Detection of very recent drug use',
      'Detection of environmental exposure to drugs'
    ]
  },
  {
    question: 'What must the donor not do in the 15 minutes before screening?',
    correct: 1,
    answers: [
      'Go to the toilet',
      'Eat, drink or smoke',
      'Talk',
      'Breathe through the mouth'
    ]
  },
  {
    question: 'Where should we conduct screening?',
    correct: 1,
    answers: [
      'In a room with everyone else',
      'One to one in a private room',
      'In a room with other donors',
      'With a qualified nurse',
    ]
  },
  {
    question: 'Which 3 crucial bits of information are detailed on a consent form?',
    correct: 0,
    answers: [
      'Identification, medication and signature',
      'Identification, medical history and signature',
      'Place of birth, medical history and surname',
      'Surname, diet and location'
    ]
  },
  {
    question: 'Why should you ask the donor to select the test from the box?',
    correct: 3,
    answers: [
      'To remove accusations of result fixing',
      'To include them in the process',
      'So that the donor can see that the test is within its expiry date',
      'All of the above'
    ]
  },
  {
    question: 'Before you handle the drug test, what should you do?',
    correct: 0,
    answers: [
      'Put on gloves',
      'Wear a protective jacket',
      'Disinfect the surfaces',
      'Move to the sink',
    ]
  },
  {
    question: 'What is the best way to collect an oral fluid sample?',
    correct: 1,
    answers: [
      'Swab inside the cheek',
      'Close your lips around the stem and squash the sponge with teeth and tongue',
      'Lick the collector sponge',
      'Chew the collector with the molars',
    ]
  },
  {
    question: 'When do you know that you have collected sufficient samples?',
    correct: 1,
    answers: [
      'The sponge enlarges',
      'The sponge is soft and saturated',
      'The collector makes an audible alarm',
      'The sponge detaches from the collector',
    ]
  },
  {
    question: 'Why might it take longer to collect a sample from the donor?',
    correct: 0,
    answers: [
      'The donor is dehydrated or has been working physically',
      'The donor has just eaten and is full of food',
      'The donor has a cold and is taking paracetamol',
      'The donor is working a night shift'
    ]
  },
  {
    question: 'When you add a sample to the test, how do you know it is running correctly?',
    correct: 0,
    answers: [
      'There is a pink wash that runs up each test strip',
      'The collector changes colour',
      'There is a pink wash that runs up some of the test strips',
      'The test becomes warm as the chemical reaction takes place',
    ]
  },
  {
    question: 'What do you do if not all the tests strips run?',
    correct: 2,
    answers: [
      'Open another test and try again',
      'Shake the test with vigour',
      'Use the collector to re-sample',
      'Give the test a sharp tap on a hard surface',
    ]
  },
  {
    question: 'What do you do if a test strip has no Test Line (T) but the background still has a pink tint?',
    correct: 1,
    answers: [
      'Write it up as a non-negative',
      'Give the test a little more time to develop',
      'Write the result up as negative',
      'Send the sample for confirmation',
    ]
  },
  {
    question: 'When do we send a sample for lab confirmation?',
    correct: 0,
    answers: [
      'When the result is a non-negative',
      'When we think it should be a non-negative',
      'All samples should be sent to the lab',
      'When the screening result is invalid',
    ]
  },
  {
    question: 'When do we collect the confirmation samples?',
    correct: 1,
    answers: [
      'At the next scheduled meeting with the donor',
      'As soon as possible after screening',
      'The same day',
      'After the donor has had a drink',
    ]
  },
  {
    question: 'Why is chain of custody important?',
    correct: 0,
    answers: [
      'Because you are ensuring the samples can\'t be switched or tampered with',
      'It is a legal requirement',
      'Because it prolongs the life of the sample',
      'It keeps the samples out of sunlight',
    ]
  },
  {
    question: 'When should the sample be sent to the lab?',
    correct: 2,
    answers: [
      'Within a month',
      'To arrive 7 days after collection',
      'As soon as possible',
      'At the expiry date of the kit',

    ]
  },
  {
    question: 'What does the confirmation result show?',
    correct: 3,
    answers: [
      'Identifies that an analyte in a non-negative sample was an illicit substance',
      'Whether a non-negative with declared medication is a negative result',
      'That a declaration regarding what was found in the sample is true or false',
      'All of the above',
    ]
  },
  {
    question: 'What happens to the drug test after screening?',
    correct: 1,
    answers: [
      'Donor keeps the test',
      'The test is disposed of',
      'The test is kept as a record',
      'The test is sent to the lab',
    ]
  }
]
